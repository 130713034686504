import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Release from '../components/Release';
import logo from '../logo.svg';

function AzScreen({ releases, releaseIsLoading }) {
  const azReleases = releases.filter(
    (release) => release.label === 'A-Z Records'
  );

  return (
    <div>
      <div className="py-3 bg-secondary">
        <Container>
          <Row>
            <Col sm={10} md={6} lg={5} xl={5}>
              <img
                height="100"
                className="mx-auto d-block"
                src={logo}
                alt="logo"
              />

              <h3 className="mt-1 text-primary text-center">A-Z Records:</h3>
            </Col>
            <Col>
              <p className="mt-2 text-primary">
                Founded in 2016 in Sheffield, A-Z has been a platform for my own
                music, as well as other music makers I love such as JVXTA and
                Sangre Voss. We're mainly vinyl, but also offer all downloads
                via our bandcamp. Also on the label are collaborations with
                Sheffield band Hot Diamond Aces, Sleepsang and London based
                singer Sheyi. Check out the full discography here.
              </p>
              <p className="mt-2 text-primary">
                Distributed by All Ears, Sheffield
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          {azReleases.map((release) => (
            <Col key={release._id} sm={10} md={6} lg={5} xl={5}>
              <Release release={release} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default AzScreen;
