import React, { useState, useEffect } from 'react';
import Feature from '../components/Feature';
import { Container } from 'react-bootstrap';

import Loader from '../components/Loader';

function FeatureScreen() {
  const [mixes, setMixes] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchMixes() {
      const data = await fetch('/api/mixes')
        .then((response) => response.json())
        .then((data) => {
          data.reverse();
          setMixes(data);
          setIsLoading(false);
        });
    }

    fetchMixes();
  }, []);

  return (
    <>
      <div>
        <Container className="mb-1 pb-3 py-4 bg-danger ">
          <h3>Mixes and Features</h3>
          <p className="text-black">
            A list of interviews, DJ mixes and features I've done over the years
            for various publications.
          </p>
        </Container>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="bg-black pb-3">
          {mixes.map((mix) => (
            <Feature mix={mix} />
          ))}
        </div>
      )}
    </>
  );
}

export default FeatureScreen;
