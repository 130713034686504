import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';

import Header from './components/Header';
import Footer from './components/Footer';

import HomeScreen from './screens/HomeScreen';
import ReleaseScreen from './screens/ReleaseScreen';
import AzScreen from './screens/AzScreen';
import ArtistScreen from './screens/ArtistScreen';
import BalamiiScreen from './screens/BalamiiScreen';
import AboutScreen from './screens/AboutScreen';
import FeatureScreen from './screens/FeatureScreen';
import PageNotExist from './screens/PageNotExist';

function App() {
  const [releases, setReleases] = useState([]);
  const [releaseIsLoading, setReleaseIsLoading] = useState(true);

  useEffect(() => {
    async function fetchReleases() {
      const data = await fetch('/api/releases')
        .then((response) => response.json())
        .then((data) => {
          data.reverse();
          setReleases(data);
          setReleaseIsLoading(false);
        });
    }
    fetchReleases();
  }, []);

  return (
    <Router>
      <div className="bg-primary" id="main">
        <Header />
        <main>
          <Routes>
            <Route
              path="/"
              element={
                <HomeScreen
                  releases={releases}
                  releaseIsLoading={releaseIsLoading}
                />
              }
              exact
            />
            <Route
              path="/az_records"
              element={
                <AzScreen
                  releases={releases}
                  releaseIsLoading={releaseIsLoading}
                />
              }
              exact
            />
            <Route
              path="/releases/:id"
              element={
                <ArtistScreen
                  releases={releases}
                  releaseIsLoading={releaseIsLoading}
                />
              }
            />
            <Route
              path="/release/:id"
              element={<ReleaseScreen releases={releases} />}
            />
            <Route path="/dj/balamii" element={<BalamiiScreen />} />
            <Route path="/dj/mixes" element={<FeatureScreen />} />
            <Route path="/about" element={<AboutScreen />} />
            <Route path="*" element={<PageNotExist />} />
          </Routes>
        </main>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
