import { Navbar, Nav, NavDropdown, Container, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faHeadphones, faRecordVinyl } from '@fortawesome/free-solid-svg-icons';
import logo_green from '../logo_green.jpg';
import logo from '../logo.svg';
import balamii from '../balamii.svg';

function Header() {
  return (
    <header>
      <Navbar bg="light" collapseOnSelect expand="lg">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                alt=""
                src={logo_green}
                width="49"
                height="49"
                className="d-inline-block align-center"
              />{' '}
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Brand>Al Zanders</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {/* Releases */}
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon icon={faRecordVinyl} /> Releases
                  </>
                }
                id="basic-nav-dropdown"
              >
                {/* All */}
                <LinkContainer to="/">
                  <NavDropdown.Item>All</NavDropdown.Item>
                </LinkContainer>

                <NavDropdown.Divider />

                {/* A-Z */}
                <LinkContainer to="/az_records">
                  <NavDropdown.Item>
                    <img id="navLogo" src={logo} />
                    A-Z Records
                  </NavDropdown.Item>
                </LinkContainer>

                {/* Al Zanders */}
                <LinkContainer to="/releases/al_zanders">
                  <NavDropdown.Item>Al Zanders</NavDropdown.Item>
                </LinkContainer>

                {/* Lodger */}
                <LinkContainer to="/releases/lodger">
                  <NavDropdown.Item>Lodger</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              {/* DJ */}
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon icon={faHeadphones} /> DJ
                  </>
                }
                id="basic-nav-dropdown"
              >
                {/*  Balamii */}
                <LinkContainer to="/dj/balamii">
                  <NavDropdown.Item>
                    <img id="navLogo" src={balamii} />
                    Balamii Radio
                  </NavDropdown.Item>
                </LinkContainer>

                {/*  Mixes  */}
                <LinkContainer to="/dj/mixes">
                  <NavDropdown.Item>Mixes & Features</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              {/* About */}
              <LinkContainer to="/about">
                <Nav.Link>About</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="bg-light">
        <Container>
          <p className="mb-0 p-2 text-center text-black">
            DJ bookings and enquires: <span></span>
            <a href="mailto: henry@admusicgroup.com">henry@admusicgroup.com </a>
          </p>
        </Container>
      </div>
    </header>
  );
}

export default Header;
