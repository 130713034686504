function PageNotExist() {
  return (
    <div className="text-danger">
      <p>This page does not exist</p>
      <p>
        Redirect home{' '}
        <a style={{ color: 'white' }} href="/">
          here
        </a>
      </p>
    </div>
  );
}

export default PageNotExist;
