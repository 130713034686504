import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import aboutpic from '../aboutpic.jpg';
import aboutpic2 from '../aboutpic2.jpg';

function AboutScreen() {
  return (
    <>
      <div className="bg-secondary">
        <Container>
          <br />
          <h3 className="text-info">About:</h3>
          <Row className="text-black ">
            <Col>
              <p>
                Al Zanders, known for his various releases on Phonica Records,
                his track 'Long Gone', and being the first signee to Roy Davis
                Jr's label in almost 20 years, first found his love for dance
                music in Sheffield in the early 2010s.{' '}
              </p>
              <p>
                To begin, Al put out a string of edits under 'Lodger', which
                proved successful and put his name on the map. Since then he has
                moved on to more advanced productions, getting plays on BBC
                Essential and Mixmag cover mixes.
              </p>
            </Col>
            <Col sm={5} md={4} lg={4}>
              <Image src={aboutpic} fluid />
            </Col>
          </Row>
          <br />
        </Container>
      </div>

      <div className="bg-black">
        <Container>
          <br />
          <Row className="text-white ">
            <Col>
              <p>
                As well as music making, Zanders has over the years cemented his
                reputation as an eclectic and reliable DJ. His mixes for the
                Vinyl Factory, DJ Mag and more illustrate his ability to go
                between many musical styles seamlessly. You can usually catch
                him playing in the UK, though he has also gone on to play at
                Gottwood, Lost Village and Dimensions festivals, as well as
                clubs in Europe and Japan.
              </p>

              <p>
                For bookings please contact <span></span>
                <a
                  style={{ color: 'white' }}
                  href="mailto: henry@admusicgroup.com"
                >
                  henry@admusicgroup.com
                </a>
              </p>
            </Col>
            <Col sm={5} md={4} lg={4}>
              <Image src={aboutpic2} fluid />
            </Col>
          </Row>
        </Container>
        <br />
      </div>
    </>
  );
}

export default AboutScreen;
