import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Image,
  ListGroup,
  Button,
  ListGroupItem,
  Container,
} from 'react-bootstrap';
import BandcampPlayer from 'react-bandcamp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

import Loader from '../components/Loader';

function ReleaseScreen({ releases }) {
  const params = useParams();
  const navigate = useNavigate();

  const [release, setRelease] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (params.id <= releases.length) {
      async function fetchRelease() {
        const data = await fetch(`/api/release/${params.id}`)
          .then((response) => response.json())
          .then((data) => {
            setRelease(data);
            setIsLoading(false);
          });
      }

      fetchRelease();
    } else {
      navigate('/');
    }
  }, []);

  return (
    <div className="bg-white py-3">
      <Container className="my-5 bg-white">
        {isLoading ? (
          <Loader />
        ) : (
          <Row className="bg-white">
            {/* Image */}
            <Col sm={8} md={6} lg={4}>
              <Image
                className="rounded-circle mb-3"
                src={release.image}
                alt={release.title}
                fluid
              />
            </Col>
            {/* Text and buttons */}
            <Col>
              <ListGroup variant="flush">
                <ListGroup.Item
                  className="border-0
            "
                >
                  <h3>{release.title}</h3>
                </ListGroup.Item>

                <ListGroupItem>
                  <h5>{release.artist}</h5>
                </ListGroupItem>
                <br />
                <ListGroupItem className="text-secondary border-0">
                  {release.label}
                </ListGroupItem>

                <ListGroup.Item className="text-light">
                  {release.description}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup>
                <ListGroup.Item className=" d-flex justify-content-start border-0">
                  {/* Buy Link */}
                  <a
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                    href={release.buy}
                  >
                    <Button
                      className="btn-block my-3"
                      type="button"
                      id="buyButton"
                      // onClick={addToCartHandler}
                    >
                      <FontAwesomeIcon icon={faCartShopping} /> Buy
                    </Button>
                  </a>

                  {/* Go back */}
                  <Link style={{ textDecoration: 'none' }} to="/">
                    <Button id="backButton" className="btn-block m-3">
                      Go Back
                    </Button>
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col>
              <BandcampPlayer
                artwork="small"
                album={release.bandcamp}
                height="250px"
              />
            </Col>
          </Row>
        )}

        <br />
      </Container>
    </div>
  );
}

export default ReleaseScreen;
