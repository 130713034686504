import React from 'react';
import { Image, Carousel, Button } from 'react-bootstrap';

function News({ news }) {
  return (
    <Carousel>
      <Carousel.Item id="heroMain">
        <Image
          src={news[0].image}
          alt="first picture"
          className="d-block w-100"
        />
        <Carousel.Caption id="heroCaption">
          <h1 className="text-black">{news[0].title}</h1>
          <br />
          <p className="text-black">{news[0].description}</p>
          <a
            target="_blank"
            style={{ textDecoration: 'none' }}
            href={news[0].link}
          >
            <Button className="btn-block my-3" type="button" id="buyButton">
              Find out more
            </Button>
          </a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item id="heroMain">
        <Image
          src={news[1].image}
          alt="first picture"
          className="d-block w-100"
        />
        <Carousel.Caption id="heroCaption">
          <h1 className="text-black">{news[1].title}</h1>
          <br />
          <p className="text-black">{news[1].description}</p>
          <a
            target="_blank"
            style={{ textDecoration: 'none' }}
            href={news[1].link}
          >
            <Button className="btn-block my-3" type="button" id="buyButton">
              Find out more
            </Button>
          </a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item id="heroMain">
        <Image
          src={news[2].image}
          alt="first picture"
          className="d-block w-100"
        />
        <Carousel.Caption id="heroCaption">
          <h1 className="text-black">{news[2].title}</h1>
          <br />
          <p className="text-black">{news[2].description}</p>
          <a
            target="_blank"
            style={{ textDecoration: 'none' }}
            href={news[2].link}
          >
            <Button className="btn-block my-3" type="button" id="buyButton">
              Find out more
            </Button>
          </a>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default News;
