import { Row, Col, Container } from 'react-bootstrap';
import Release from '../components/Release';
import { useParams } from 'react-router-dom';

function AzScreen({ releases, releaseIsLoading }) {
  const params = useParams();
  const selectedArtist = params.id.replaceAll('_', ' ');

  const artistReleases = releases.filter(
    (release) => release.artist.toLowerCase().includes(selectedArtist) === true
  );

  return (
    <>
      {!releaseIsLoading && (
        <Container className="mt-3">
          {artistReleases.length >= 1 ? (
            <div>
              <h2 className="text-secondary">{selectedArtist}:</h2>

              <Row>
                {artistReleases.map((release) => (
                  <Col key={release._id} sm={10} md={6} lg={5} xl={5}>
                    <Release release={release} />
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <p className="text-danger">No artists found.</p>
          )}
        </Container>
      )}
    </>
  );
}

export default AzScreen;
