import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Release from '../components/Release';
import News from '../components/News';
import Loader from '../components/Loader';
import Paginator from '../components/Paginator';

function HomeScreen({ releases, releaseIsLoading }) {
  const [news, setNews] = useState([]);

  // Loading
  const [newsIsLoading, setNewsIsLoading] = useState(true);

  // Fetch
  useEffect(() => {
    async function fetchNews() {
      const data = await fetch('/api/news')
        .then((response) => response.json())
        .then((data) => {
          data.reverse();
          setNews(data);
          setNewsIsLoading(false);
        });
    }
    fetchNews();
  }, []);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [releasesPerPage] = useState([6]);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Get current releases for Pagination
  const indexOfLastRelease = currentPage * releasesPerPage;
  const indexOfFirstRelease = indexOfLastRelease - releasesPerPage;
  const currentReleases = releases.slice(
    indexOfFirstRelease,
    indexOfLastRelease
  );

  return (
    <>
      {/* News */}
      {newsIsLoading ? <Loader /> : <News news={news} />}
      {/*  */}

      {/* Discography */}
      <Container className="mt-3">
        {!releaseIsLoading && (
          <Row>
            {currentReleases.map((release) => (
              <Col key={release._id} sm={10} md={6} lg={5} xl={5}>
                <Release release={release} />
              </Col>
            ))}
          </Row>
        )}
        <Row>
          <Paginator
            releasesPerPage={releasesPerPage}
            totalReleases={releases.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </Row>
      </Container>
    </>
  );
}

export default HomeScreen;
