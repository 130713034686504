import balamii from '../balamii.svg';
import { Row, Col, Container } from 'react-bootstrap';
import ReactPlayer from 'react-player';

function BalamiiScreen() {
  return (
    <>
      <div className="bg-danger py-3">
        <Container>
          <Row>
            <Col sm={10} md={6} lg={5} xl={5}>
              <img
                className="mx-auto d-block"
                height="100px"
                src={balamii}
                alt="bal"
              />

              <h3 className="mt-1 text-primary text-center">Balamii Radio</h3>
            </Col>
            <Col>
              <p className="mt-5 text-primary">
                Every 3rd Wednesday of the month 11am-1pm. Listen in the playter
                below:
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <>
          <ReactPlayer
            height="500px"
            width="100%"
            url="https://soundcloud.com/al_zanders/sets/balamii?si=6c362fa0c7ca43e79806cb21d767ec3b&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
          />
        </>
      </div>
    </>
  );
}

export default BalamiiScreen;
