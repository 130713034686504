import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faMixcloud,
  faBandcamp,
} from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <footer className="bg-info p-2">
      <Container>
        <Row>
          <Col className="text-danger text-center py-4">
            Copyright &copy; Al Zanders {''}
          </Col>

          <Col className="text-center py-4">
            {/* Instagram */}{' '}
            <a
              target="_blank"
              href="https://www.instagram.com/al_zanders/?hl=en"
              id="faIcon"
            >
              <FontAwesomeIcon id="faIcon" target="_blank" icon={faInstagram} />
            </a>
            {/* Mixcloud */}{' '}
            <a href="https://www.mixcloud.com/alzanders/">
              <FontAwesomeIcon id="faIcon" target="_blank" icon={faMixcloud} />
            </a>
            {/* Bandcamp */}{' '}
            <a href="https://azrecords.bandcamp.com/">
              <FontAwesomeIcon id="faIcon" icon={faBandcamp} />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
