import React from 'react';
import { Row, Col, Image, ListGroup, Button, Container } from 'react-bootstrap';

function Feature({ mix }) {
  return (
    <Container className="bg-secondary mb-1">
      <Row className="pt-10">
        {/* Image */}
        <Col xs={9} sm={5} md={4} lg={3}>
          <Image className="mh-100 py-2" src={mix.image} alt="" fluid />
        </Col>
        {/* Text  */}
        <Col>
          <ListGroup variant="flush">
            <ListGroup.Item className="border-0 bg-secondary">
              <h3>{mix.title}</h3>
            </ListGroup.Item>

            <br />

            <ListGroup.Item className="text-black bg-secondary">
              {mix.description}
            </ListGroup.Item>
          </ListGroup>

          <ListGroup>
            <ListGroup.Item className="bg-secondary d-flex justify-content-start border-0">
              {/* Find out more Link */}
              <a
                target="_blank"
                style={{ textDecoration: 'none' }}
                href={mix.link}
              >
                <Button className="btn-block my-3" type="button" id="buyButton">
                  Find Out More
                </Button>
              </a>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default Feature;
