import React from 'react';
import { Nav, Pagination } from 'react-bootstrap';

function Paginator({ releasesPerPage, totalReleases, paginate, currentPage }) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalReleases / releasesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <Nav>
      <Pagination size="sm">
        {pageNumbers.map((number) => (
          <Pagination.Item
            onClick={() => paginate(number)}
            key={number}
            id={number === currentPage ? 'paginatorSelected' : 'paginatorItem'}
          >
            {number}
          </Pagination.Item>
        ))}
      </Pagination>
    </Nav>
  );
}

export default Paginator;
