import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Release({ release }) {
  return (
    <Link to={`/release/${release._id}`}>
      <Card className="my-3 p-3 rounded  text-secondary" id="releaseCard">
        <Row>
          <Col className="pr-0">
            <Link to={`/release/${release._id}`}>
              <Card.Img className="rounded-circle" src={release.image} />
            </Link>
          </Col>
          <Col>
            <Card.Body>
              <Card.Title as="h4">
                <strong className="text-info">{release.title}</strong>
              </Card.Title>

              <Card.Subtitle as="p">
                <p className="my-3 text-light">{release.artist}</p>
              </Card.Subtitle>
              <Card.Text as="p">
                <small className="my-3 text-light font-weight-light">
                  <em>{release.label}</em>
                </small>
              </Card.Text>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </Link>
  );
}

export default Release;
